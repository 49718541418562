import React from 'react';
import styles from './styles.scss';
import { MenuStateItem } from '@store/ducks/application/menu';
import { Link } from '@src/components/Link';
import { LiteShopContext } from 'index';
import clsx from 'clsx';
import Badge from '@src/components/UIKit/Badge';

const renderItems = (
    items: MenuStateItem['children'],
    depth: number,
    onClose?: () => void
): React.ReactNodeArray => {
    return items.map((submenu) => {
        const link = submenu.action.link;
        const haveChilds = Boolean(submenu.children.length);
        const isHeader = depth === 1;
        const isNew = submenu.is_new;

        return (
            <div
                key={submenu.id}
                className={clsx({
                    [styles.subcategory]: isHeader,
                    [styles.subcategoryItem]: !isHeader,
                })}
            >
                <Link
                    key={submenu.id}
                    context={{
                        ...(link as LiteShopContext),
                        name: link?.name ?? 'index',
                    }}
                    disabled={!submenu.action.href}
                    nativeLinkProps={{
                        href: submenu.action.href ?? '/',
                        className: clsx({
                            [styles.subcategoryHeader]: isHeader,
                            [styles.subcategoryItemLink]: !isHeader,
                        }),
                        onClick: onClose,
                    }}
                >
                    {submenu.title}
                    {isNew && (
                        <Badge type="newBadge" className={styles.badgePosition}>
                            new
                        </Badge>
                    )}
                </Link>
                {haveChilds &&
                    renderItems(submenu.children, depth + 1, onClose)}
            </div>
        );
    });
};

type MenuItemProps = {
    menuItem: MenuStateItem;
    onMouseOver?: React.UIEventHandler;
    onClose?: () => void;
};

const Submenu: React.FC<MenuItemProps> = ({
    menuItem,
    onMouseOver,
    onClose,
}) => {
    if (!menuItem.children.length) {
        return null;
    }

    return (
        <div className={styles.wrapper} onMouseOver={onMouseOver}>
            <div className={styles.submenu}>
                {renderItems(menuItem.children, 1, onClose)}
            </div>
        </div>
    );
};
export default React.memo(Submenu);
