import React from 'react';
import Button, { ButtonProps } from '@components/UIKit/Button';
import * as styles from './styles.scss';
import clsx from 'clsx';

type BurgerBtnProps = { active: boolean } & ButtonProps;
const BurgerBtn: React.FC<BurgerBtnProps> = ({
    active,
    className,
    ...buttonProps
}) => {
    return (
        <Button
            {...buttonProps}
            className={clsx(
                styles.burgerButton,
                active && styles.active,
                className
            )}
        >
            <div>
                <div className={styles.bar1}></div>
                <div className={styles.bar2}></div>
                <div className={styles.bar3}></div>
            </div>
        </Button>
    );
};

export default BurgerBtn;
