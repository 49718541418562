import React from 'react';
import App, { AppContext, AppInitialProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { getStore, StoreWithPersist } from '@src/store';
import 'normalize.css';
import '@public/fonts/AvenirNextCyr/AvenitNextCyr.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-multi-carousel/lib/styles.css';
import '@src/scss/style.scss';
import { RootState } from '@store/ducks';
import { fetchBase } from '@store/ducks/application';
import Layout from '@src/components/Layout/Layout';
import { ParseUrlWithContext, LiteShopContext, KoaAppState } from '../index';
import { IncomingHttpHeaders } from 'http';
import { contextActions } from '@store/ducks/application/context';
import { resolve, build } from '@vsemayki/url-resolver';
import { MenuKeys, getMenu } from '@store/ducks/application/menu';
import { getCart } from '@store/ducks/cart';
import { checkLocation } from '@ducks/geo';
import { getBanners } from '@ducks/application/banners';
import {
    getShopSettings,
    shopSettingsSelector,
    ShopSettingsState,
} from '@ducks/application/shopSettings';
import { getPlatformFromUserAgent } from '@ducks/application/user';
import 'focus-visible';
import ErrorBoundary from '@components/ErrorBoundary';
import { getSeo } from '@ducks/seo';
import { setDomain } from '@ducks/application/domain';
import * as Sentry from '@sentry/node';
import { SentryInit } from 'sentry/init';
import {
    GoptimizeTypes,
    getExperiments,
    sendExperimentEvent,
    ExperimentsProvider,
} from '@vsemayki/goptimize';
import { experimentsOptions } from '@src/data/experiments';

SentryInit();

export type AppContextWithStore = AppContext & {
    ctx: AppContext['ctx'] & ParseUrlWithContext;
    reduxStore: StoreWithPersist;
    ContextClass: KoaAppState['context'];
};

export type AppInitialPropsWithRedux = AppInitialProps & {
    initialReduxState: RootState;
    headers?: IncomingHttpHeaders;
    err?: Error;
    experiments: GoptimizeTypes.IExperiment[];
};
// @ts-ignore
export default class LiteShop extends App {
    reduxStore: StoreWithPersist;
    state: { shopSettings: ShopSettingsState };
    err: Error | undefined;
    experiments: GoptimizeTypes.IExperiment[];

    static async getInitialProps(
        appContext: AppContextWithStore
    ): Promise<AppInitialPropsWithRedux> {
        const ctx = appContext.ctx;
        const headers = ctx.req?.headers ?? {};
        const reduxStore = getStore({}, headers);

        const ContextClass: KoaAppState['context'] = resolve(
            ctx.asPath ?? (ctx.query.name ? build(ctx.query) : '')
        );
        appContext.ContextClass = ContextClass;

        const query: LiteShopContext = {
            ...ctx.query,
            ...ContextClass.getContextData(),
        };

        const menus: ReadonlyArray<MenuKeys> = ['top', 'header_partners'];

        ctx.query = query;

        const userAgent = process.browser
            ? navigator.userAgent
            : ctx.req?.headers['user-agent'] ?? '';

        // Provide the store to getInitialProps of pages
        appContext.reduxStore = reduxStore;

        await Promise.all([
            reduxStore.dispatch(fetchBase()),
            reduxStore.dispatch(
                getBanners({ placement: 'top', context: ContextClass })
            ),
            reduxStore.dispatch(getShopSettings()),
            ...menus.map((menu) => reduxStore.dispatch(getMenu(menu))),
        ]);
        reduxStore.dispatch(contextActions.setContextData(query));
        reduxStore.dispatch(getPlatformFromUserAgent(userAgent));

        let appProps: unknown = {};
        if (typeof appContext.Component.getInitialProps === 'function') {
            try {
                appProps = await appContext.Component.getInitialProps(
                    // @ts-ignore
                    appContext
                );
            } catch (error) {
                console.error('error on getInitialProps:>> ', error);
            }
        }

        if (process.browser) {
            reduxStore.dispatch(setDomain(window.location.origin));
        } else {
            const domain = ctx.req
                ? `${ctx.req.headers['x-forwarded-proto']}://${ctx.req.headers.host}`
                : '';
            reduxStore.dispatch(setDomain(domain));
        }

        reduxStore.dispatch(getSeo);

        let experiments = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const nextCtx: any = ctx.req && ctx.res ? ctx : null;
        experiments = getExperiments(nextCtx, experimentsOptions);

        return {
            pageProps: appProps,
            initialReduxState: reduxStore.getState(),
            headers: headers,
            experiments,
        };
    }

    constructor(props: AppInitialPropsWithRedux) {
        // @ts-ignore
        super(props);
        this.reduxStore = getStore(props.initialReduxState, props.headers);
        const State = this.reduxStore.getState();
        const ShopSettingsState = shopSettingsSelector(State);
        this.state = { shopSettings: ShopSettingsState };
        this.err = props.err;
        this.experiments = props.experiments;
    }

    insertGTM(el: 'script' | 'noscript' = 'script') {
        try {
            const gtm = 'GTM-MHC5CZB';

            const gtmScript = document.createElement(el);

            gtmScript.innerHTML =
                el === 'noscript'
                    ? `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtm}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
                    : // eslint-disable-next-line no-useless-escape
                      `(function(w,d,s,l,i) {w[l]=w[l]||[];w[l].push( \{'gtm.start': new Date().getTime(),event:'gtm.js'}
        );var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtm}');`;

            const parent = el === 'noscript' ? document.body : document.head;

            const theFirstChild = parent.firstChild;

            parent.insertBefore(gtmScript, theFirstChild);
        } catch (error) {
            console.error('_app::insertGTM', error);
        }
    }

    componentDidMount() {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentElement) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
        this.reduxStore.dispatch(getCart());
        this.reduxStore.dispatch(checkLocation());

        this.insertGTM();
        this.insertGTM('noscript');

        sendExperimentEvent(this.experiments);
    }

    componentDidCatch(error: Error) {
        Sentry.captureException(error);
    }

    render() {
        const { Component, pageProps } = this.props;
        const { shopSettings } = this.state;
        const { title, description } = this.reduxStore.getState().seo;
        const setTypeFavicon = (favicon?: string) => {
            if (favicon && favicon.includes('.png')) return 'image/png';

            if (
                favicon &&
                (favicon.includes('.jpg') || favicon.includes('.jpeg'))
            )
                return 'image/jpg';

            return 'image/x-icon';
        };

        return (
            <React.Fragment>
                <Head>
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                    />
                    <title>
                        {title ||
                            (shopSettings.title ?? 'vsemayki partnershop')}
                    </title>
                    {shopSettings.favicon && (
                        <link
                            rel="shortcut icon"
                            href={shopSettings.favicon}
                            type={setTypeFavicon(shopSettings.favicon)}
                        />
                    )}

                    <meta name="title" content={title} />
                    <meta property="og:title" content={title} />

                    <meta name="description" content={description} />
                    <meta property="og:description" content={description} />
                </Head>
                <Provider store={this.reduxStore}>
                    <ExperimentsProvider experiments={this.experiments}>
                        <Layout>
                            <ErrorBoundary>
                                <Component {...pageProps} err={this.err} />
                            </ErrorBoundary>
                        </Layout>
                    </ExperimentsProvider>
                </Provider>
            </React.Fragment>
        );
    }
}
