import React, { useReducer, useCallback } from 'react';
import styles from './styles.scss';
import { menuSpecificSelector } from '@store/ducks/application/menu';
import MenuItem from './MenuItem';
import Submenu from './Submenu';
import NavButtons from '../NavButtons';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import City from '../City';
import Logo from '../Logo';

type MenuReducerState = { activeFirstLvlMenu: number | undefined };
type MenuReducerActions = {
    type: 'setFirstLvlMenu';
    payload: number | undefined;
};

const MenuReducer: React.Reducer<MenuReducerState, MenuReducerActions> = (
    state,
    action
) => {
    switch (action.type) {
        case 'setFirstLvlMenu':
            return { ...state, activeFirstLvlMenu: action.payload };

        default:
            throw new Error('Menu reducer somehow get default case');
    }
};

type MenuProps = unknown;

const Menu: React.FC<MenuProps> = () => {
    const topMenuState = useSelector(menuSpecificSelector('header_partners'));
    const [state, dispatch] = useReducer(MenuReducer, {} as MenuReducerState);

    const setHoveredDebounced = useCallback(
        debounce((menuId: MenuReducerState['activeFirstLvlMenu']) => {
            dispatch({ type: 'setFirstLvlMenu', payload: menuId });
        }, 127),
        []
    );

    const onFirstMenuHover = (
        menuId: MenuReducerState['activeFirstLvlMenu']
    ) => () => {
        setHoveredDebounced(menuId);
    };

    const onFirstMenuClick = (
        menuId: MenuReducerState['activeFirstLvlMenu']
    ) => (event: React.MouseEvent<HTMLAnchorElement>) => {
        // for touchscreens at first click on menu we open it
        if (menuId !== state.activeFirstLvlMenu) {
            event.preventDefault();
            dispatch({ type: 'setFirstLvlMenu', payload: menuId });
        } else {
            dispatch({ type: 'setFirstLvlMenu', payload: undefined });
        }
    };

    const activeMenuItem = topMenuState.find(
        (item) => item.id === state.activeFirstLvlMenu
    );

    return (
        <div className={styles.menu}>
            <City className={styles.city} />
            <div className={styles.logo}>
                <Logo className={styles.logoLink} />
            </div>
            <NavButtons className={styles.navButtons} />
            <nav
                className={styles.firstLvlMenu}
                onMouseLeave={onFirstMenuHover(undefined)}
            >
                {topMenuState.map((menuItem) => (
                    <MenuItem
                        key={menuItem.id}
                        isHovered={state.activeFirstLvlMenu === menuItem.id}
                        itemState={menuItem}
                        onMouseOver={onFirstMenuHover}
                        onClick={onFirstMenuClick}
                    />
                ))}
                {activeMenuItem && (
                    <Submenu
                        menuItem={activeMenuItem}
                        onMouseOver={() => setHoveredDebounced.cancel()}
                        onClose={() => setHoveredDebounced(undefined)}
                    />
                )}
            </nav>
        </div>
    );
};

export default React.memo(Menu);
