import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    APIStatusFullResponse,
    StatusHistory,
    Postamat,
} from '@api/types/status';
import { AppThunk, RootState } from '..';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import { loadingActions } from '../application/loading';
import get from 'lodash/get';

const { CALL_API } = ApiMiddleware;

export type ITrackData = {
    alias?: string;
    message: string;
    created_at: string;
};

export type ITrackingInfo = {
    order_hash: string;
    postamat: Postamat;
    track_data: ITrackData[];
};

type IInitialState = {
    tracking?: ITrackingInfo[];
    error?: string;
};

const initialState: IInitialState = {
    tracking: undefined,
    error: undefined,
};

const Aliases = {
    Accept: 'stage1',
    Sended: 'stage23',
    WaitPVZ: 'stage24',
    Recived: 'stage7',
    Returned: 'stage4',
};

const statusPage = createSlice({
    initialState,
    name: 'StatusPage',
    reducers: {
        setTracking: (state, action: PayloadAction<Array<StatusHistory>>) => {
            state.tracking = action.payload.map((track) => {
                const findIndexInHistory = (alias: string) =>
                    track.statuses_history.findIndex(
                        (el) => el.stage.alias === alias
                    );

                const AcceptIndex = findIndexInHistory(Aliases.Accept);

                const SendHistory = track.statuses_history
                    .slice(AcceptIndex)
                    .map((el) => {
                        let message = el.stage.title;
                        if (el.stage.alias === Aliases.WaitPVZ) {
                            message += ' ' + get(track, 'postamat.address', '');
                        }
                        return {
                            alias: el.stage.alias,
                            message: message,
                            created_at: el.date.split('+')[0],
                        };
                    });

                // remove tracks that coalesce with SendHistory
                const TrackingFiltered = track.tracking.filter(
                    (track) =>
                        !SendHistory.some(
                            (history) =>
                                Number(new Date(history.created_at)) ===
                                Number(new Date(track.created_at))
                        )
                );

                const track_data = [...SendHistory, ...TrackingFiltered];

                track_data.sort((a, b) => {
                    const aDate = Number(new Date(a.created_at));
                    const bDate = Number(new Date(b.created_at));

                    if (aDate === bDate) {
                        return 0;
                    }

                    return aDate > bDate ? 1 : -1;
                });
                return {
                    order_hash: track.order_hash,
                    track_data,
                    postamat: track.postamat,
                };
            });
        },
        setError: (state, action: PayloadAction<string | undefined>) => {
            state.error = action.payload;
        },
    },
});

export const statusPageActions = statusPage.actions;

export default statusPage.reducer;

export const statusPageSelector = (state: RootState) => state.pages.status;

export const getStatus: (
    email: string,
    order_id?: string
) => AppThunk<Promise<void>> = (email, order_id) => async (dispatch) => {
    try {
        dispatch(loadingActions.setLoading(true));
        const statusData = await dispatch<APIStatusFullResponse>({
            type: CALL_API,
            payload: {
                url: `rest/info/order-statuses`,
                method: 'POST',
                data: { login: email, order_id },
            },
        });
        dispatch(statusPageActions.setTracking(statusData));
        if (statusData.length === 0) {
            dispatch(
                statusPageActions.setError(
                    'Не найдено активных заказов для указанного телефона или e-mail. Проверьте введенные данные'
                )
            );
        } else {
            dispatch(statusPageActions.setError(undefined));
        }
        dispatch(loadingActions.setLoading(false));
    } catch (error) {
        console.error('cant get status info', error);
        dispatch(loadingActions.setLoading(false));
        dispatch(statusPageActions.setError(error.toString()));
    }
};
