import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import { SentryConfig } from './config';

export const SentryInit = () => {
    const integrations = [];
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
        new RewriteFrames({
            iteratee: (frame) => {
                if (!frame.filename) return frame;

                frame.filename = frame.filename.replace('/app', 'app:///');
                frame.filename = frame.filename.replace('.next', '_next');
                return frame;
            },
        })
    );

    //TODO: rework on process env when we will ready;
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations,
        dsn: SentryConfig.dsn,
    });

    Sentry.setTag(
        'type',
        process.browser ? SentryConfig.platform.CSR : SentryConfig.platform.SSR
    );
};
