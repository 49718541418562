import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './ducks';
import storage from 'redux-persist/lib/storage';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    PersistConfig,
} from 'redux-persist';

import { ApiMiddleware } from '@vsemayki/shared-frontend/';
import { IncomingHttpHeaders } from 'http';
import * as SentryReact from '@sentry/react';

const { createApiMiddleware } = ApiMiddleware;

const persistConfig: PersistConfig<RootState> = {
    key: 'root',
    storage,
    whitelist: (['cart', 'geo'] as Array<keyof RootState>) as string[],
    blacklist: (['pages'] as Array<keyof RootState>) as string[],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initStore = (preloadedState = {}, headers?: IncomingHttpHeaders) => {
    const store = configureStore({
        reducer: persistedReducer,
        preloadedState,
        middleware: getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(createApiMiddleware({ headers })),
        enhancers: [SentryReact.createReduxEnhancer()],
        devTools: true,
    });

    const storeWithPersist = {
        ...store,
        persist: process.browser && persistStore(store),
    };

    return storeWithPersist;
};

export type StoreWithPersist = ReturnType<typeof initStore>;
export type StoreDispatch = StoreWithPersist['dispatch'];
export const getStore = (
    preloadedState = {},
    headers?: IncomingHttpHeaders
): StoreWithPersist => {
    // Always make a new store if server, otherwise state is shared between requests
    if (!process.browser) {
        return initStore(preloadedState, headers);
    }

    // Create store if unavailable on the client and set it on the window object
    if (!window.__NEXT_REDUX_STORE__) {
        window.__NEXT_REDUX_STORE__ = initStore(preloadedState, headers);
    }
    return window.__NEXT_REDUX_STORE__;
};
