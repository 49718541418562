import { RootState } from '@ducks/index';
import { productSelector } from '@ducks/product';
import find from 'lodash/find';

export type SeoPayload = {
    title: string;
    description?: string;
};

type GetSeoType = {
    [key: string]: (getState: () => RootState) => SeoPayload;
};

const getSeoInfo: GetSeoType = {
    index: (getState) => {
        const { application } = getState();
        const shopTitle = application.shopSettings.title;

        return {
            title: `${shopTitle}`,
        };
    },

    article: (getState) => {
        const state = getState();
        const { data, activeVariant } = productSelector(state);
        const { application } = state;
        const shopTitle = application.shopSettings.title;

        if (!activeVariant || !data) {
            return {
                title: `${shopTitle}`,
                description: '',
            };
        }

        const { categories, design } = data;
        const alias = design.alias;
        const categoryTitle = categories[0]?.title || '';
        const price = activeVariant.price;

        return {
            title: `${shopTitle}`,
            description: `${alias} купить по цене ${price}. Большой выбор ${categoryTitle.toLowerCase()} с принтами с доставкой по России | Интернет-магазин ${shopTitle}`,
        };
    },

    products: (getState) => {
        const {
            context: { collection, filter, query },
            tags,
            structure,
            shopSettings,
        } = getState().application;

        const subCategoryId =
            collection?.subCategory &&
            `${collection.category}__${collection.subCategory}`;

        const catalogTypeTitle =
            find(structure, { id: filter?.value })?.title || '';

        let catalogCollectionTitle = find(tags, {
            id: subCategoryId || collection?.category,
        })?.title;

        const shopTitle = shopSettings.title;

        if (!catalogCollectionTitle) {
            catalogCollectionTitle =
                query?.sort === 'new'
                    ? 'Новинки'
                    : query?.sort === 'sell'
                    ? 'Популярное'
                    : '';
        }

        return {
            title: `${shopTitle}`,
            description: `${
                catalogCollectionTitle
                    ? `${catalogCollectionTitle} - тысячи`
                    : 'Тысячи '
            } принтов, мужских, женских, детских ${catalogTypeTitle}. Большой каталог ${catalogTypeTitle} с принтами - доставка по России | Интернет-магазин ${shopTitle}`,
        };
    },

    cart: (getState) => {
        const { application } = getState();
        const shopTitle = application.shopSettings.title;

        return {
            title: `${shopTitle}`,
        };
    },

    status: (getState) => {
        const { application } = getState();
        const shopTitle = application.shopSettings.title;

        return {
            title: `${shopTitle}`,
        };
    },

    default: (getState) => {
        const { application } = getState();
        const shopTitle = application.shopSettings.title;

        return {
            title: `${shopTitle}`,
            description: '',
        };
    },
};

export default getSeoInfo;
