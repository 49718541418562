import React from 'react';
import clsx from 'clsx';
import * as styles from './styles.scss';

type DividerProps = JSX.IntrinsicElements['hr'];
const Divider: React.FC<DividerProps> = (props) => {
    return <hr {...props} className={clsx(styles.divider, props.className)} />;
};

export default Divider;
