import React, { useState, useRef, useEffect } from 'react';
import BurgerBtn from './BurgerBtn';
import * as styles from './styles.scss';
import clsx from 'clsx';
import { Link } from '@components/Link';
import CartButton from '../NavButtons/CartButton';
import { useSelector } from 'react-redux';
import { menuSpecificSelector } from '@store/ducks/application/menu';
import { LiteShopContext } from 'index';
import useHideBodyScroll from '@src/hooks/useHideBodyScroll';
import City from '../City';
import ReactDOM from 'react-dom';
import Logo from '../Logo';
import SearchBoldIcon from '@src/components/UIKit/Icons/search_bold_inline.svg';
import dynamic from 'next/dynamic';

const MobileSearch = dynamic(() => import('../Search/MobileSearch'));

const MobileMenu: React.FC = () => {
    const topMenuState = useSelector(menuSpecificSelector('top'));
    const [isOpen, setOpen] = useState(false);
    const [isVisibleSearch, setVisibleSearch] = useState(false);
    useHideBodyScroll(isOpen || isVisibleSearch);

    const mobileMenuDOMRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!process.browser) return;
        if (!mobileMenuDOMRef.current) {
            const divEl = document.createElement('div');
            divEl.id = 'MobileMenu';
            document.body.appendChild(divEl);

            (mobileMenuDOMRef as React.MutableRefObject<
                HTMLDivElement
            >).current = divEl;
        }
    }, []);

    return (
        <>
            <div className={styles.mobileMenu}>
                <div className={styles.leftControls}>
                    <BurgerBtn
                        active={isOpen}
                        className={styles.burgerBtn}
                        onClick={() => setOpen((prev) => !prev)}
                    />
                    <SearchBoldIcon
                        onClick={() => setVisibleSearch(!isVisibleSearch)}
                        className={clsx(styles.searchIcon, {
                            [styles.active]: isVisibleSearch,
                        })}
                    />
                </div>
                <div className={styles.logo}>
                    <Logo className={styles.logoLink} />
                </div>
                <CartButton
                    showText={false}
                    linkProps={{ className: styles.cart }}
                />
                {isVisibleSearch && (
                    <MobileSearch
                        className={styles.search}
                        placeholder={'Название товара или принта'}
                        redirectCallback={() => setVisibleSearch(false)}
                    />
                )}

                {mobileMenuDOMRef.current &&
                    ReactDOM.createPortal(
                        <div
                            className={clsx(
                                styles.container,
                                isOpen && styles.containerActive
                            )}
                        >
                            <div className={styles.mobileMenuContent}>
                                {topMenuState.map((menuItem) => (
                                    <Link
                                        key={menuItem.id}
                                        context={
                                            menuItem.action
                                                .link as LiteShopContext
                                        }
                                        nativeLinkProps={{
                                            className:
                                                styles.mobileMenuContentLink,
                                            onClick() {
                                                setOpen(false);
                                            },
                                        }}
                                    >
                                        {menuItem.title}
                                    </Link>
                                ))}
                            </div>
                            <City className={styles.city} />
                        </div>,
                        mobileMenuDOMRef.current
                    )}
            </div>
            {isVisibleSearch && (
                <div
                    className={styles.overlay}
                    onClick={() => setVisibleSearch(false)}
                />
            )}
        </>
    );
};

export default MobileMenu;
