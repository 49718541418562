import { combineReducers } from 'redux';
import cart from './cart';
import status from './status';
import cartDelivery, { CartDeliveryPageState } from './cartDelivery';
import deliveryType from './deliverytype';
import order from './order';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const CartDeliveryPersistConfig: PersistConfig<CartDeliveryPageState> = {
    key: 'cartDelivery',
    storage,
    whitelist: ['user', 'geo', 'address'] as Array<keyof CartDeliveryPageState>,
};

export default combineReducers({
    status,
    cart,
    cartDelivery: persistReducer(CartDeliveryPersistConfig, cartDelivery),
    deliveryType,
    order,
});
