import { APIDeliveryGeoTextResponse } from '@api/types/delivery/geoText';
import { APIOrderOptionsResponse } from '@api/types/order/options';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import { AppThunkAction, RootState } from '..';
import { CartDeliveryPageState } from './cartDelivery';
import { keyBy, get } from 'lodash';
import { getDays } from '@src/utils/getPlural';

const { apiRequest } = ApiMiddleware;

type TDeliveries = CartDeliveryPageState['deliveries'][0] & {
    postomats: CartDeliveryPageState['postamats'];
};

export type DeliveryTypePageState = { deliveries: TDeliveries[] } & Pick<
    CartDeliveryPageState,
    'geo' | 'cities' | 'groups' | 'payments'
>;

const initialState: DeliveryTypePageState = {
    geo: {
        city: { id: '', prefix: '', region: '', title: '' },
        region: '',
        country: {
            active: false,
            allow_russian: false,
            id: 0,
            iso_alphabetic: '',
            iso_numeric: 0,
            name: '',
            name_alt: null,
            name_en: null,
            name_official: null,
            position: 0,
            split_type: null,
            suggest: [],
            title: '',
        },
        cityTrusted: true,
    },
    cities: [],
    deliveries: [],
    groups: [],
    payments: [],
};
const deliveryTypePage = createSlice({
    initialState,
    name: 'DeliveryTypePage',
    reducers: {
        setCountry: (
            state,
            action: PayloadAction<DeliveryTypePageState['geo']['country']>
        ) => {
            state.geo.country = action.payload;
        },
        setCities: (
            state,
            actions: PayloadAction<DeliveryTypePageState['cities']>
        ) => {
            state.cities = actions.payload;
        },
        setCity: (
            state,
            action: PayloadAction<DeliveryTypePageState['geo']['city']>
        ) => {
            state.geo.city = action.payload;
        },
        setRegion: (
            state,
            action: PayloadAction<DeliveryTypePageState['geo']['region']>
        ) => {
            state.geo.region = action.payload;
        },
        changeFields: (
            state,
            action: PayloadAction<Partial<DeliveryTypePageState>>
        ) => {
            return { ...state, ...action.payload };
        },
    },
});

export const deliveryTypePageActions = deliveryTypePage.actions;

export default deliveryTypePage.reducer;

export const deliveryTypePageSelector = (state: RootState) =>
    state.pages.deliveryType;

export const preparePickupPoints = ({
    points,
    payments: payments,
}: {
    points: CartDeliveryPageState['deliveries'];
    payments: CartDeliveryPageState['payments'];
}) => {
    const colors = {
        VSEMAYKI: '#f371d1',
        CDEK: '#064000',
        BOXBERRY: '#FF0000',
        DPD: '#0000FF',
        PICK_POINT: '#FFA500',
    };

    const paymentsKeyed = keyBy(payments, 'alias');

    const resolvePayment = (
        allowedPayments: CartDeliveryPageState['allowedPayments'],
        cashless: boolean
    ) => {
        if (allowedPayments.includes(paymentsKeyed.POSTAL?.id)) {
            return `Наличными ${cashless ? ', банковской картой' : ''}`;
        }

        return 'банковской картой, <span style="color:#F173CD">100% предоплата</span>';
    };

    const result = points.reduce<
        Record<string, CartDeliveryPageState['postamats'][0]>
    >((acc, delivery) => {
        const deliveryText = getDays(delivery.duration.max); //getDurationText([delivery]);

        if (!acc[delivery.brand_alias]) {
            const hints = [
                delivery.cost === 0 ? 'Бесплатно' : `${delivery.cost} руб.`,
            ];
            if (!delivery.allowed_payments.includes(paymentsKeyed.POSTAL?.id)) {
                hints.push('100% предоплата');
            }

            hints.push(deliveryText);

            const title = delivery.brand_alias.replace('_', '');

            acc[delivery.brand_alias] = {
                alias: delivery.brand_alias,
                title:
                    title.charAt(0).toUpperCase() +
                    title.slice(1).toLowerCase(),
                color: get(
                    delivery,
                    'pickup_points.0.delivery_brand_color',
                    colors[delivery.brand_alias as keyof typeof colors]
                ),
                hints,
                points: [],
            };
        }

        const points =
            delivery.pickup_points?.map((point) => {
                const title = delivery.brand_alias
                    .replace('_', '')
                    .toLowerCase();

                let content = '<div>';
                if (point.work_time) {
                    content += `<p>Время работы: ${point.work_time}</p>`;
                }
                const resolvedPayment = resolvePayment(
                    point.allowed_payments,
                    point.cashless
                );
                if (resolvedPayment) {
                    content += `<p>Оплата: ${resolvedPayment}</p>`;
                }
                if (deliveryText) {
                    content += `<p>Доставим: ${deliveryText}</p>`;
                }
                if (point.description) {
                    content += `<p>Описание: ${point.description}</p>`;
                }
                content += '</div>';

                return {
                    id: point.id,
                    delivery: point.delivery_alias,
                    allowed_payments: delivery.allowed_payments,
                    address: point.address,
                    cost: delivery.cost,
                    description:
                        point.type === 'POSTOMAT'
                            ? `Постамат ${title}`
                            : `Пункт выдачи ${title}`,
                    coords: [point.latitude, point.longitude] as [
                        number,
                        number
                    ],
                    content: content,
                    services: [point.type === 'STORE' ? 'PVZ' : point.type],
                };
            }) ?? [];

        acc[delivery.brand_alias].points.push(...points);

        return acc;
    }, {});

    return Object.values(result);
};
export const citySuggest: AppThunkAction<string> = (city) => async (
    dispatch
) => {
    if (city.length === 0) {
        dispatch(deliveryTypePageActions.setCities([]));
        return;
    }

    try {
        const citiesSuggestionsResponse = await dispatch<
            APIDeliveryGeoTextResponse
        >(apiRequest({ url: `/rest/delivery/geo?text=${city}` }));

        const citiesSuggestions = citiesSuggestionsResponse.map((item) => {
            return {
                id: item.info.uid,
                title: item.match,
                region: item.info.region,
                prefix: item.type.short,
            };
        });

        dispatch(deliveryTypePageActions.setCities(citiesSuggestions));
    } catch (e) {
        console.error('[checkout:city_suggest:fail] ', e.toString());
        dispatch(deliveryTypePageActions.setCities([]));
    }
};

export const getDelivery: AppThunkAction = () => async (dispatch, getState) => {
    try {
        const {
            pages: {
                deliveryType: { geo },
            },
        } = getState();
        const response = await dispatch<APIOrderOptionsResponse>(
            apiRequest({
                method: 'POST',
                url: '/rest/order/options',
                data: {
                    cart: [],
                    address: {
                        country: geo.country.title || '',
                        region: geo.city.title || '',
                        city: geo.region || '',
                    },
                },
            })
        );
        const pickupPoints = response.deliveries.filter(
            (delivery) => delivery.group === 'PICKUP'
        );
        const postomats = preparePickupPoints({
            points: pickupPoints,
            payments: response.payments,
        });
        const delivery = {
            ...response,
            deliveries: response.deliveries.map((delivery) => ({
                ...delivery,
                postomats: postomats.filter(
                    (postomat) => postomat.alias === delivery.brand_alias
                ),
            })),
        };

        dispatch(deliveryTypePageActions.changeFields(delivery));
    } catch (error) {
        console.error(error);
    }
};
