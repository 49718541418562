import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '.';
import getSeoInfo, { SeoPayload } from '@src/config/seo';

export interface SeoState {
    title: string;
    description: string;
}

const initialState: SeoState = {
    title: '',
    description: '',
};

const seo = createSlice({
    name: 'Seo',
    initialState,
    reducers: {
        setSeo: (state, action: PayloadAction<SeoPayload>) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setSeo } = seo.actions;
export default seo.reducer;
export const seoSelector = (state: RootState) => state.seo;

export const getSeo: AppThunk = (dispatch, getState) => {
    const { name } = getState().application.context;

    if (getSeoInfo[name]) {
        dispatch(setSeo(getSeoInfo[name](getState)));
    } else {
        dispatch(setSeo(getSeoInfo.default(getState)));
    }
};
