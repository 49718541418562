import React from 'react';
import styles from './styles.scss';
import { MenuStateItem } from '@store/ducks/application/menu';
import { Link } from '@src/components/Link';
import { LiteShopContext } from 'index';
import clsx from 'clsx';
import { shallowEqual } from 'react-redux';

type MenuItemProps = {
    itemState: MenuStateItem;
    isHovered: boolean;
    onMouseOver: (menuId: number | undefined) => () => void;
    onClick: (
        menuId: number | undefined
    ) => (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

const MenuItem: React.FC<MenuItemProps> = ({
    itemState,
    isHovered,
    onMouseOver,
    onClick: onTouchStart,
}) => {
    return (
        <Link
            key={itemState.id}
            context={itemState.action.link as LiteShopContext}
            nativeLinkProps={{
                className: styles.menuLink,
                onClick: onTouchStart(itemState.id),
                //@ts-ignore
                'data-autotest': 'MenuItem',
            }}
        >
            <div
                onMouseOver={onMouseOver(itemState.id)}
                className={clsx(styles.item, {
                    [styles.active]: isHovered,
                })}
            >
                {itemState.title}
            </div>
        </Link>
    );
};
export default React.memo(
    MenuItem,
    (prevProps, nextProps) =>
        shallowEqual(prevProps.itemState, nextProps.itemState) &&
        prevProps.isHovered === nextProps.isHovered
);
