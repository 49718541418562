import useGTMdataLayer from '@src/hooks/useDataLayer';
import { CONTEXT, resolve } from '@vsemayki/url-resolver';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const usePagesAnalytic = () => {
    const gtm = useGTMdataLayer();
    const router = useRouter();
    const currentPageName = resolve(router.asPath).getName();

    useEffect(() => {
        switch (currentPageName) {
            case CONTEXT.index:
                gtm.current.push({ event: 'home_page_shop' });
                gtm.current.push({ event: 'all_pages_shop' });
                break;

            case CONTEXT.catalogue:
                if (router.asPath.includes('search_page')) {
                    gtm.current.push({ event: 'search_page_shop' });
                } else {
                    gtm.current.push({ event: 'catalog_page_shop' });
                }
                gtm.current.push({ event: 'all_pages_shop' });
                break;

            case CONTEXT.article: {
                gtm.current.push({ event: 'product_page_shop' });
                gtm.current.push({ event: 'all_pages_shop' });
                break;
            }
            case CONTEXT.cart:
                gtm.current.push({ event: 'cart_page_shop' });
                gtm.current.push({ event: 'all_pages_shop' });
                break;

            case CONTEXT.cartDelivery: {
                gtm.current.push({ event: 'checkout_page_shop' });
                gtm.current.push({ event: 'all_pages_shop' });
                break;
            }

            case CONTEXT.orderSuccess:
                gtm.current.push({ event: 'purchase_page_shop' });
                gtm.current.push({ event: 'all_pages_shop' });
                break;

            default:
                gtm.current.push({ event: 'all_pages_shop' });
                break;
        }
    }, [currentPageName, router.pathname, router.asPath]);
};

export default usePagesAnalytic;
