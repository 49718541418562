import React from 'react';
import styles from './styles.scss';
import Header from '@components/Header';
import usePageIsLoading from '@src/hooks/usePageIsLoading';
import Spinner from '@uikit/Spinner';
import { useSelector } from 'react-redux';
import { loadingSelector } from '@store/ducks/application/loading';
import Footer from '@components/Footer';
import BannersCarousel from '@components/BannersCarousel';
import { useRouter } from 'next/router';
import { CONTEXT, resolve } from '@vsemayki/url-resolver';
import usePagesAnalytic from './usePageAnalyticLayout';
import CookieNotification from '@components/CookieNotification';

const RoutesWithoutLayout: string[] = [CONTEXT.constructor];
const RoutesWithoutSpinner: string[] = [CONTEXT.catalogue];

type LayoutProps = unknown;
const Layout: React.FunctionComponent<LayoutProps> = (props) => {
    usePagesAnalytic();

    const [isPageLoading, nextRoute] = usePageIsLoading();
    const isComponentLoading = useSelector(loadingSelector);
    const router = useRouter();

    if (
        RoutesWithoutLayout.includes(router.pathname.replace('/', '')) &&
        props.children
    ) {
        return <span>{props.children}</span>;
    }
    const prevPageName = resolve(router.asPath).getContextData().name;
    const nextPageName = resolve(nextRoute).getContextData().name;
    //HACK for catalog, hide spinner if user navigates from /catalog to /catalog
    const isRouteWithoutSpinner =
        prevPageName === nextPageName &&
        RoutesWithoutSpinner.includes(resolve(nextRoute).getContextData().name);

    const showSpiner = isRouteWithoutSpinner
        ? false
        : isComponentLoading || isPageLoading;

    return (
        <div className={styles.container}>
            <Header />
            <Spinner
                show={showSpiner}
                color="primary"
                className={styles.spinner}
                size={200}
            />
            <BannersCarousel />
            <div className={styles.grid}>{props.children}</div>
            <div id="portals" />
            <Footer />
            <CookieNotification />
        </div>
    );
};

export default Layout;
