import React from 'react';
import * as styles from './styles.scss';
import clsx from 'clsx';
import * as colors from './colors.scss';

type Colors = keyof typeof colors;
type LoaderProps = {
    show: boolean;
    color?: Colors;
    size?: number;
} & JSX.IntrinsicElements['span'];
const Loader: React.FC<LoaderProps> = ({
    show,
    color,
    className,
    size = 44,
    ...spanProps
}) => {
    return (
        <span
            {...spanProps}
            className={clsx(styles.wrapper, show && styles.active, className)}
        >
            <svg
                className={styles.spinnerSvg}
                viewBox="22 22 44 44"
                style={{ width: `${size}px`, height: `${size}px` }}
            >
                <circle
                    className={clsx(styles.spinner, colors[color ?? 'base'])}
                    cx="44"
                    cy="44"
                    r="20.2"
                    fill="none"
                    strokeWidth="3.6"
                ></circle>
            </svg>
        </span>
    );
};

export default Loader;
