import React from 'react';
import styles from './styles.scss';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import clsx from 'clsx';
import useMobileLayout from '@src/hooks/useMobileLayout';
import useFallHeader from '@src/hooks/useFallHeader';

type HeaderProps = unknown;
const Header: React.FC<HeaderProps> = () => {
    const isMobileLayout = useMobileLayout();
    const isHeaderFall = useFallHeader();

    return (
        <header
            className={clsx(styles['root'], isHeaderFall && styles['fall'])}
        >
            {isMobileLayout ? <MobileMenu /> : <Menu />}
        </header>
    );
};

export default Header;
