import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button, { ButtonProps } from '@uikit/Button';
import JsCookie from 'js-cookie';
import styles from './styles.scss';
import { domainSelector } from '@ducks/application/domain';

const COOKIE_NAME = 'vm_cookies_accepted';

const CookieNotification = () => {
    const [isCookieAccepted, setIsCookieAccepted] = useState<boolean>(true);
    const domainState = useSelector(domainSelector);

    const confirmHandler = useCallback(() => {
        JsCookie.set(COOKIE_NAME, '1', {
            path: '/',
            domain: window.location.hostname.split('.').slice(-2).join('.'),
            expires: 365,
        });
        setIsCookieAccepted(true);
    }, []);

    useEffect(
        () => setIsCookieAccepted(!!Number(JsCookie.get(COOKIE_NAME))),
        []
    );

    if (isCookieAccepted) return null;

    const buttonProps: ButtonProps = {
        color: 'dark',
        variant: 'filled',
        onClick: confirmHandler,
        className: styles.notification__button,
    };

    return (
        <section className={styles.notification}>
            <p>
                Мы используем файлы cookie. Это позволяет нам анализировать
                взаимодействие посетителей с сайтом и делать его лучше.
                Продолжая пользоваться сайтом, вы соглашаетесь с использованием
                файлов cookie, а также с&nbsp;
                <a
                    className={styles.notification__link}
                    href={`${domainState}/doc/user_agreement`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Политикой конфиденциальности сайта
                </a>
                .
            </p>
            <Button {...buttonProps}>Принять</Button>
        </section>
    );
};

export default CookieNotification;
