import React from 'react';
import Button from '@src/components/UIKit/Button';
import CartIcon from '@src/components/UIKit/Icons/cart_inline.svg';
import { Link } from '@src/components/Link';
import { CONTEXT } from '@vsemayki/url-resolver';
import { useSelector } from 'react-redux';
import { cartSelector, CartState } from '@store/ducks/cart';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/ducks';
import * as styles from './styles.scss';

const cartAmountSelector = createSelector<
    RootState,
    CartState['items'],
    number
>(
    (state) => cartSelector(state).items,
    (cartItemSelector) =>
        cartItemSelector.reduce<number>(
            (total, cartItem) => (total += cartItem.amount),
            0
        )
);
type CartButtonProps = {
    showText?: boolean;
    linkProps?: JSX.IntrinsicElements['a'];
};
const CartButton: React.FC<CartButtonProps> = ({
    showText = true,
    linkProps,
}) => {
    const cartAmount = useSelector(cartAmountSelector);
    const haveItems = cartAmount > 0;
    return (
        <Link context={{ name: CONTEXT.cart }} nativeLinkProps={linkProps}>
            <Button className={styles.cartButton} tabIndex={-1}>
                <CartIcon className={styles.cartIcon} />
                {haveItems && (
                    <span className={styles.cartAmount}>{cartAmount}</span>
                )}
                {showText && 'Корзина'}
            </Button>
        </Link>
    );
};

export default CartButton;
