import React from 'react';
import CartButton from './CartButton';
import * as styles from './styles.scss';
import clsx from 'clsx';
import dynamic from 'next/dynamic';

const DesktopSearch = dynamic(
    () => import('@components/Header/Search/DesktopSearch')
);

type NavButtonsProps = JSX.IntrinsicElements['div'];
const NavButtons: React.FC<NavButtonsProps> = (props) => {
    return (
        <div {...props} className={clsx(styles.navButons, props.className)}>
            <div className={clsx(styles.wrapper)}>
                <DesktopSearch className={styles.search} collapsedDefault />
                <CartButton />
            </div>
        </div>
    );
};

export default React.memo(NavButtons);
