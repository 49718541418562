import type { ApiMiddlewareTypes } from '@vsemayki/shared-frontend';
import { Action, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import application from './application';
import cart from './cart';
import catalog from './catalog';
import dialog from './dialog';
import geo from './geo';
import pages from './pages';
import product from './product';
import search from './search';
import constructorItems from './constructorItems';
import seo from './seo';

const rootReducer = combineReducers({
    application,
    cart,
    catalog,
    dialog,
    geo,
    pages,
    product,
    search,
    constructorItems,
    seo,
});

export type RootState = ReturnType<typeof rootReducer>;

export type ThunkActionWithApi<R, S, E, A extends Action> = (
    dispatch: ApiMiddlewareTypes.DispatchWithApi & ThunkDispatch<S, E, A>,
    getState: () => S,
    extraArgument: E
) => R;

export type AppThunk<R = void, T = string> = ThunkActionWithApi<
    R,
    RootState,
    null,
    Action<T>
>;

export type AppThunkAction<
    ArgumentType = undefined,
    ReturnType = Promise<void>
> = (...arg: ArgumentType[]) => AppThunk<ReturnType>;

export default rootReducer;
