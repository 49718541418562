import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import { AppThunkAction, RootState } from '.';

const { apiRequest } = ApiMiddleware;

type ItemsInConstructorItemType = Omit<ConstructorStateType, 'items'> & {
    cost: number;
};

export type ConstructorStateType = {
    alias: string;
    id: number;
    position: number;
    title: string;
    images: Array<{ url: string; position: number }>;
    items: Array<ItemsInConstructorItemType>;
};

const initialState: ConstructorStateType[] = [];

const constructorItems = createSlice({
    name: 'ConstructorItems',
    initialState,
    reducers: {
        setItems: (_state, action: PayloadAction<ConstructorStateType[]>) => {
            return action.payload;
        },
    },
});

const { setItems } = constructorItems.actions;
export default constructorItems.reducer;
export const constructorItemsSelector = (state: RootState) =>
    state.constructorItems;

export const getConstructorList: AppThunkAction = () => async (dispatch) => {
    const resp: ConstructorStateType[] = await dispatch(
        apiRequest({ url: `catalog/constructor` })
    );
    dispatch(setItems(resp));
};
