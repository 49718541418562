import React, { useState } from 'react';
import {
    shopSettingsSelector,
    shopSettingsActions,
} from '@ducks/application/shopSettings';
import { useSelector } from 'react-redux';
import DefaultLogo from '@uikit/Icons/default_logo.png';
import Link from '@components/Link';
import clsx from 'clsx';
import useTypedDispatch from '@src/hooks/useTypedDispatch';
import styles from './styles.scss';

type LogoProps = { className: string };

const Logo = (props: LogoProps) => {
    const ShopSettingsState = useSelector(shopSettingsSelector);
    const dispatch = useTypedDispatch();
    const [imgError, setImgError] = useState(false);

    return (
        <Link
            context={{ name: 'index' }}
            nativeLinkProps={{ className: clsx(props.className) }}
        >
            {ShopSettingsState.shopLogo ? (
                <img
                    src={ShopSettingsState.shopLogo}
                    alt={ShopSettingsState.title ?? 'Logo'}
                    onError={() => {
                        if (imgError) return;
                        setImgError(true);
                        dispatch(shopSettingsActions.setShopLogo(''));
                    }}
                    className={styles.logoImg}
                />
            ) : (
                <img
                    src={DefaultLogo}
                    alt={ShopSettingsState.title ?? ''}
                    aria-label="Logo"
                    className={styles.logoImg}
                />
            )}
        </Link>
    );
};

export default React.memo(Logo);
