//TODO: remove it after eslint update it blame line 64 for no reason
/* eslint-disable no-irregular-whitespace */
import Link from '@components/Link';
import { contextSelector, ContextState } from '@ducks/application/context';
import { structureSelector } from '@ducks/application/structure';
import { tagsSelector } from '@ducks/application/tags';
import Button from '@uikit/Button';
import { CONTEXT } from '@vsemayki/url-resolver';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import CatBoredImage from './images/cat_bored_inline.svg';
import styles from './not_found.module.scss';

type NotFoundButtonProps = { context: ContextState };
const NotFoundButton: React.FC<NotFoundButtonProps> = (props) => (
    <Link
        context={props.context}
        nativeLinkProps={{ className: styles.not_found__navigaton__button }}
    >
        <Button
            color="primary"
            variant="filled"
            tabIndex={-1}
            className={styles.not_found__navigaton__button}
        >
            {props.children}
        </Button>
    </Link>
);

type ErrorNotFoundProps = JSX.IntrinsicElements['section'];
const ErrorNotFound = (props: ErrorNotFoundProps) => {
    const ContextState = useSelector(contextSelector);
    const TagsState = useSelector(tagsSelector);
    const StructureState = useSelector(structureSelector);

    const ContextMapped = {
        category:
            ContextState.collection &&
            TagsState.find((el) => el.id === ContextState.collection?.category),
        subcategory:
            ContextState.collection &&
            TagsState.find(
                (el) =>
                    el.id ===
                    `${ContextState.collection?.category}__${ContextState.collection?.subCategory}`
            ),
        structure:
            ContextState.filter?.value &&
            StructureState.find(
                (struct) => struct.id === ContextState.filter?.value
            ),
    };

    return (
        <section {...props} className={clsx(styles.not_found, props.className)}>
            <div className={styles.not_found__wrapper}>
                <CatBoredImage />
                <div className={styles.not_found__title}>
                    <h2 className={styles.not_found__title__header}>
                        <b>Что-то пошло не так...</b>
                    </h2>
                    Попробуйте обновить страницу
                    <br /> или выберите другой раздел
                </div>
                <div className={styles.not_found__navigaton}>
                    {ContextMapped.structure && (
                        <NotFoundButton
                            context={{
                                ...ContextState,
                                collection: undefined,
                                query: undefined,
                            }}
                        >
                            Все товары «{ContextMapped.structure.title}»
                        </NotFoundButton>
                    )}
                    <NotFoundButton context={{ name: CONTEXT.constructor }}>
                        Создать товар
                    </NotFoundButton>
                    {ContextMapped.category && (
                        <NotFoundButton
                            context={{
                                ...ContextState,
                                filter: undefined,
                                query: undefined,
                            }}
                        >
                            В категорию «
                            {ContextMapped.subcategory?.title ??
                                ContextMapped.category.title}
                            »
                        </NotFoundButton>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ErrorNotFound;
