import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunkAction } from '@ducks/index';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import { APIOrdersResponse, Cart } from '@api/types/orders/orders';

const { apiRequest } = ApiMiddleware;

type OrderSuccessState = Pick<APIOrdersResponse, 'id' | 'receiver'> & {
    cart: Cart[];
};
const initialState: OrderSuccessState = {
    id: 0,
    receiver: {
        name: '',
        phone: '',
        email: '',
        comment: '',
        address: {
            id: 0,
            country: '',
            region: '',
            region_type: '',
            city: '',
            address: '',
            postcode: '',
        },
    },
    cart: [],
};

const orderSuccessPage = createSlice({
    initialState,
    name: 'OrderSuccessPage',
    reducers: {
        setApiResponse: (_state, action: PayloadAction<OrderSuccessState>) =>
            action.payload,
        setInitialValues: () => initialState,
    },
});

export const orderSuccessPageActions = orderSuccessPage.actions;

export default orderSuccessPage.reducer;

export const orderSuccessPageSelector = (state: RootState) =>
    state.pages.order.success;

export const getOrderData: AppThunkAction<{
    email: string;
    order_id: string;
}> = ({ email, order_id }) => async (dispatch) => {
    try {
        const response = await dispatch<APIOrdersResponse>(
            apiRequest({
                url: `rest/orders/${Number(order_id)}?email=${email}`,
            })
        );
        // @ts-ignore thx backend
        if ((response as unknown)?.code === 400) {
            throw new Error('bad response');
        }

        dispatch(
            orderSuccessPageActions.setApiResponse({
                id: response.id,
                receiver: response.receiver,
                cart: response.cart,
            })
        );
    } catch (error) {
        console.error('cant get order data: ', error);
    }
};
