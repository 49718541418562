import { GoptimizeTypes } from '@vsemayki/goptimize';

// experiment data:
/* {
    id,
    name,
    isEligible: () => true, // a function that allows to run an experiment on custom conditions
    variants: [
         {
             weight, // weight of variant for wieght-random
             id,
             name,
             component // active component
         }
    ],
    weight: 1, // weight of experiment for wieght-random
    device: ['mobile'|& 'tablet' |& 'desktop' | 'all'], // device for which the experiment is active
}
*/

export const experimentsOptions: GoptimizeTypes.IExperimentOptions = {
    cookieName: 'vm_lite_shops_goptimize_experiment',
    experiments: [],
};
