import { useState, useEffect } from 'react';
import Router from 'next/router';

/**
 * provides hook on router events `routeChangeStart` and `routeChangeComplete`
 */
const usePageIsLoading = (): [boolean, string] => {
    const [isLoading, setLoading] = useState(false);
    const [nextRoute, setNextRoute] = useState('');

    useEffect(() => {
        const handleRouteChangeStart = (newRoute: string) => {
            setNextRoute(newRoute);
            setLoading(true);
        };
        const handleRouteChangeEnd = () => {
            setLoading(false);
        };

        Router.events.on('routeChangeStart', handleRouteChangeStart);
        Router.events.on('routeChangeComplete', handleRouteChangeEnd);
        return () => {
            Router.events.off('routeChangeStart', handleRouteChangeStart);
            Router.events.off('routeChangeComplete', handleRouteChangeEnd);
        };
    }, []);

    return [isLoading, nextRoute];
};

export default usePageIsLoading;
