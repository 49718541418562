import React from 'react';
import MultiCarousel, {
    CarouselProps,
    ResponsiveType,
} from 'react-multi-carousel';
import { bannersSelector } from '@ducks/application/banners';
import Link from '@components/Link';
import { useSelector } from 'react-redux';
import useMobileLayout from '@src/hooks/useMobileLayout';
import * as styles from './styles.scss';
import { contextSelector } from '@ducks/application/context';
import { CONTEXT } from '@vsemayki/url-resolver';
import { APIBannersResponseItem } from '@api/types/banners';

const responsive: ResponsiveType = {
    all: {
        breakpoint: {
            max: Number.POSITIVE_INFINITY,
            min: 0,
        },
        items: 1,
        slidesToSlide: 1,
    },
};

type BannersCarouselProps = {
    responsive?: CarouselProps['responsive'];
} & Omit<CarouselProps, 'responsive' | 'children'>;
const BannersCarousel = (props: BannersCarouselProps) => {
    const BannersState = useSelector(bannersSelector);
    const ContextState = useSelector(contextSelector);
    const isMobile = useMobileLayout();

    const userPlatform: APIBannersResponseItem['platform'] = isMobile
        ? 'mobile'
        : 'desktop';
    const platformBanners = BannersState.top.filter((banner) => {
        return banner.platform === userPlatform || banner.platform === 'all';
    });
    const bannersCount = platformBanners.length;
    const isMoreThanOneBanner = bannersCount > 1;

    // TODO: DO IT NEXT SPRINT;
    //FIXME: remove condition and place component inside pages (index, catalog);
    // did it hacky because of deadline;
    if (
        ![CONTEXT.index, CONTEXT.catalogue].some((contextName) =>
            contextName.match(ContextState.name)
        )
    ) {
        return null;
    }

    return (
        <MultiCarousel
            swipeable={platformBanners.length > 1}
            draggable={false}
            showDots={isMoreThanOneBanner}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={isMoreThanOneBanner}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass={styles.bannersCarousel}
            removeArrowOnDeviceType={['tablet', 'mobile']}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            arrows={false}
            {...props}
        >
            {platformBanners.map((banner) => (
                <Link
                    context={banner.context}
                    key={banner.banner.url}
                    nativeLinkProps={{
                        className: styles.bannersCarouselLinks,
                    }}
                >
                    <div
                        className={styles.background}
                        style={{
                            backgroundImage: !isMobile
                                ? ''
                                : `url(${banner.banner.url})`,
                        }}
                    ></div>
                    <img
                        src={banner.banner.url}
                        alt={banner.banner.title}
                        className={styles.bannerImg}
                    />
                </Link>
            ))}
        </MultiCarousel>
    );
};

export default BannersCarousel;
