import React, { useState, useCallback, useMemo } from 'react';
import * as styles from './styles.scss';
import { useSelector } from 'react-redux';
import {
    geoSelector,
    geoActions,
    citySuggestSearch,
    GeoState as IGeoState,
} from '@ducks/geo';
import clsx from 'clsx';
import Button, { ButtonProps } from '@uikit/Button';
import Modal from '@components/Modal';
import { dialogActions } from '@ducks/dialog';
import useTypedDispatch from '@src/hooks/useTypedDispatch';
import Input from '@uikit/Input';
import { debounce } from 'lodash';
import useMobileLayout from '@src/hooks/useMobileLayout';
import getPhone from '@src/utils/cityPhones';

type CityProps = ButtonProps;
const City: React.FC<CityProps> = ({ className, ...restBtnProps }) => {
    const dispatch = useTypedDispatch();
    const GeoState = useSelector(geoSelector);
    const isMobile = useMobileLayout();

    const [countryFilter, setCountryFilter] = useState<string>('');
    const [cityFilter, setCityFilter] = useState<string>('');
    const isRussia = GeoState.country.title === 'Россия';

    const debouncedCitySuggest = useCallback(
        debounce((city: string) => dispatch(citySuggestSearch(city)), 300),
        []
    );

    const handleCitySelect = (
        city:
            | IGeoState['popularCities'][0]
            | IGeoState['autocompleteOptions'][0]
    ) => {
        dispatch(geoActions.setCity(city.label));
        dispatch(geoActions.setPhone(getPhone(city.label)));
        dispatch(geoActions.setRegion(city.region));
        dispatch(dialogActions.close('selectCity'));
    };

    const CounryList = useMemo(
        () =>
            GeoState.countries
                .filter((country) =>
                    country.suggest.some((suggestion) =>
                        suggestion.match(countryFilter.toLocaleLowerCase())
                    )
                )
                .map((country) => (
                    <Button
                        className={clsx(
                            country.title === GeoState.country.title &&
                                styles.active
                        )}
                        key={country.id}
                        onClick={() => {
                            dispatch(geoActions.setCountry(country));
                            if (country.title !== 'Россия') {
                                dispatch(geoActions.setCity(''));
                                dispatch(geoActions.setRegion(''));

                                dispatch(dialogActions.close('selectCity'));
                            }
                        }}
                    >
                        {country.title}
                    </Button>
                )),
        [GeoState, countryFilter]
    );

    return (
        <div className={clsx(styles.cityWrapper, className)}>
            <Button
                {...restBtnProps}
                className={styles.cityBtn}
                onClick={(event) => {
                    restBtnProps.onClick && restBtnProps.onClick(event);
                    dispatch(dialogActions.open('selectCity'));
                }}
                title="Выбор города"
            >
                {isRussia ? GeoState.city : GeoState.country.title}
            </Button>
            <div
                className={clsx(
                    styles.showConfirmCity,
                    GeoState.showConfirmCity &&
                        !isMobile &&
                        GeoState.city &&
                        styles.showConfirmCityActive
                )}
            >
                <div>
                    <b>Ваш город - {GeoState.city}</b>
                </div>
                <div className={styles.showConfirmCityActiveButtons}>
                    <Button
                        onClick={() => {
                            dispatch(geoActions.setShowConfirmCity(false));
                        }}
                        variant="filled"
                        color="secondary"
                        className={styles.confirmBtn}
                    >
                        Все верно
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(dialogActions.open('selectCity'));
                            dispatch(geoActions.setShowConfirmCity(false));
                        }}
                    >
                        Нет, выбрать другой
                    </Button>
                </div>
            </div>

            <Modal title="Выберите регион доставки" type="selectCity">
                <div
                    className={clsx(
                        styles.modalContent,
                        isRussia && styles.modalContentWithCity
                    )}
                >
                    <div className={styles.country}>
                        <Input
                            title="Страна"
                            onChange={(event) =>
                                setCountryFilter(event.target.value)
                            }
                            value={countryFilter}
                            helperText={
                                !CounryList.length &&
                                'К сожалению не удалось найти подходящей страны, проверьте правильность ввода'
                            }
                            haveError={!CounryList.length}
                        />
                        <div className={styles.list}>{CounryList}</div>
                    </div>

                    {isRussia && (
                        <div className={styles.city}>
                            <Input
                                title="Город"
                                onChange={(event) => {
                                    setCityFilter(event.target.value);
                                    debouncedCitySuggest(event.target.value);
                                }}
                                value={cityFilter}
                            />
                            <div className={styles.list}>
                                {isRussia && GeoState.autocompleteOptions.length
                                    ? GeoState.autocompleteOptions.map(
                                          (city) => (
                                              <Button
                                                  className={clsx(
                                                      city.label ===
                                                          GeoState.city &&
                                                          styles.active
                                                  )}
                                                  key={city.label}
                                                  onClick={() => {
                                                      handleCitySelect(city);
                                                  }}
                                              >
                                                  {city.label}
                                              </Button>
                                          )
                                      )
                                    : GeoState.popularCities.map((city) => (
                                          <Button
                                              className={clsx(
                                                  city.label ===
                                                      GeoState.city &&
                                                      styles.active
                                              )}
                                              key={city.label}
                                              onClick={() => {
                                                  handleCitySelect(city);
                                              }}
                                          >
                                              {city.label}
                                          </Button>
                                      ))}
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default React.memo(City);
