import React from 'react';
import * as Sentry from '@sentry/node';
import styles from './styles.scss';
import ErrorNotFound from './NotFound';

type ErrorBoundaryState = {
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
};
type ErrorBoundaryProps = React.PropsWithChildren<{
    fallback?: React.ReactNode;
}>;

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    state: ErrorBoundaryState;

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(
        error: ErrorBoundaryState['error'],
        errorInfo: ErrorBoundaryState['errorInfo']
    ) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });

        Sentry.captureException(error);

        console.error(error, errorInfo);
    }

    render() {
        const { props, state } = this;

        if (state.errorInfo) {
            if (props.fallback) return props.fallback;
            // Error path
            return (
                <main className={styles.errorBoundary}>
                    <ErrorNotFound className={styles.errorBoundary__error} />
                </main>
            );
        }
        // Normally, just render children
        return props.children;
    }
}

export default ErrorBoundary;
