import React from 'react';
import * as styles from './styles.scss';
import Divider from '@uikit/Divider';
import LogoSVG from '@uikit/Icons/VM_Logo_inline.svg';
import Link from '@components/Link';
import { shopSettingsSelector } from '@ducks/application/shopSettings';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
// import { menuSelector } from '@store/ducks/application/menu';
// import Link from '@components/Link';
// import { LiteShopContext } from 'index';
// import clsx from 'clsx';

const Footer: React.FC = () => {
    // const { bottom: bottomMenu } = useSelector(menuSelector);
    const ShopSettingsState = useSelector(shopSettingsSelector);

    const links = [
        {
            title: 'Где мой заказ?',
            href: '/status',
        },
        {
            title: 'Доставка',
            href: '/deliverytype',
        },
        {
            title: 'Оплата',
            href: '/paymentoperation',
        },
        {
            title: 'Обмен и возврат',
            href: '/doc/information/brak',
        },
    ];
    return (
        <footer className={styles.footer}>
            <Divider />
            <nav className={styles.footerNavigation}>
                {links.map((link) => (
                    <div key={link.href} className={styles.group}>
                        <h4 className={styles.groupMenu}>
                            <a href={link.href} className={styles.hoverable}>
                                {link.title}
                            </a>
                        </h4>
                    </div>
                ))}
                {/* {bottomMenu.map(menu => {
                    const menuHref = menu.action.href;
                    const isMenuHrefEmpty = menuHref !== '';

                    return (
                        <div key={menu.id} className={styles.group}>
                            <h4 className={styles.groupMenu}>
                                <Link
                                    context={
                                        menu.action.link as LiteShopContext
                                    }
                                    native={isMenuHrefEmpty}
                                    nativeLinkProps={{
                                        href: menuHref ? menuHref : undefined,
                                        className: clsx({
                                            [styles.hoverable]: isMenuHrefEmpty
                                        })
                                    }}
                                >
                                    {menu.title}
                                </Link>
                            </h4>
                            <ul>
                                {menu.children.map(submenu => {
                                    const submenuHref = submenu.action.href;

                                    const isSubmenuHrefEmpty =
                                        submenuHref !== '';

                                    return (
                                        <li
                                            key={submenu.id}
                                            className={styles.groupMenuItem}
                                        >
                                            <Link
                                                context={
                                                    submenu.action
                                                        .link as LiteShopContext
                                                }
                                                native={isSubmenuHrefEmpty}
                                                nativeLinkProps={{
                                                    className: clsx({
                                                        [styles.hoverable]: isSubmenuHrefEmpty
                                                    }),
                                                    href: submenuHref
                                                        ? submenuHref
                                                        : undefined
                                                }}
                                            >
                                                {submenu.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })} */}
            </nav>
            {!ShopSettingsState.isWhiteLabel && (
                <div className={styles.createdWith}>
                    <Link
                        context={{ name: 'unknown' }}
                        native
                        nativeLinkProps={{ href: 'https://vsemayki.ru' }}
                    >
                        Создано с помощью
                        <LogoSVG className={styles.createdWithLogo} />
                    </Link>
                </div>
            )}
            <span
                className={styles.footerCode}
                dangerouslySetInnerHTML={{
                    __html: decodeURIComponent(
                        decodeURIComponent(ShopSettingsState.footerCode ?? '')
                    ),
                }}
            ></span>
        </footer>
    );
};

export default React.memo(Footer);
