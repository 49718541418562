import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunkAction } from '.';
import { APISearchSuggestResponse } from '@api/types/search';
import { ApiMiddleware } from '@vsemayki/shared-frontend';

const { CALL_API } = ApiMiddleware;

export interface SearchState {
    suggestions: APISearchSuggestResponse['data'][0]['items'];
}

const initialState: SearchState = {
    suggestions: [],
};

const search = createSlice({
    name: 'Search',
    initialState,
    reducers: {
        setSuggestions: (
            state,
            action: PayloadAction<SearchState['suggestions']>
        ) => {
            state.suggestions = action.payload;
        },
    },
});
export const searchActions = search.actions;
export default search.reducer;

export const searchSelector = (state: RootState) => state.search;

export const getSearchItems: AppThunkAction<string> = (query) => async (
    dispatch
) => {
    try {
        const { data: searchResult } = await dispatch<APISearchSuggestResponse>(
            {
                type: CALL_API,
                payload: {
                    url: `/rest/catalog/suggest/?query=${query}&limit=8`,
                },
            }
        );

        if (searchResult.length > 0) {
            dispatch(searchActions.setSuggestions(searchResult[0].items));
        } else {
            dispatch(searchActions.setSuggestions([]));
        }
    } catch (error) {
        console.error('searchDuck::getSearchItems', error);
    }
};
